document.addEventListener('DOMContentLoaded', function () {
  const video = document.getElementById('video-presentation');
  const closeButton = document.getElementById('close-video');

  if (video) {
    closeButton.addEventListener('click', function () {
      closeVideo();
    });
  }

  function closeVideo() {
    video.remove();
  }
});
